// @flow

import './CTALink.scss';

import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  linkText: string,
  textBefore?: string,
  textAfter?: string,
  dark?: boolean,
  onClick?: () => void
|};

const CTALink = ({
  linkText,
  textBefore,
  textAfter,
  dark = false,
  onClick: onClickHandler
}: Props) => (
  <p
    className={classnames('CTALink fg-condensed--body', {
      'CTALink--dark': dark
    })}
  >
    {textBefore && textBefore}{' '}
    <span className="CTALink__link" onClick={onClickHandler}>
      {linkText}
    </span>{' '}
    {textAfter && textAfter}
  </p>
);

export default CTALink;
