// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';

import type { AuthDomain } from 'modules/auth-modal/types';

const ALLOWED_AUTH_DOMAIN_OVERRIDE = ['email-only', 'promotion'];

const useSoftGate = (
  force: boolean,
  authDomain: AuthDomain | null,
  experiment?: AuthDomain | null
) => {
  const path = useSelector((state) => state.router.location.pathname);

  const [modalDomain, setModalDomain] = React.useState<null | AuthDomain>(null);

  React.useEffect(() => {
    if (
      !ALLOWED_AUTH_DOMAIN_OVERRIDE.includes(experiment || authDomain) ||
      !authDomain
    ) {
      if (authDomain) {
        setModalDomain(experiment || authDomain);
      }

      return;
    }

    if (path.startsWith('/journal')) {
      setModalDomain('journal');
      return;
    }

    setModalDomain(experiment || authDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authDomain, experiment]);

  return modalDomain;
};

export default useSoftGate;
