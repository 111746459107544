import { CartAddonsState, CartAddonsAction } from './types';

const initialState: CartAddonsState = {
  products: []
};

const reducer = (
  state: CartAddonsState = initialState,
  action: CartAddonsAction
) => {
  switch (action.type) {
    case 'CART_ADDONS/GET_CART_ADDONS_SUCCESS': {
      return {
        ...state,
        products: action.products
      };
    }
    default:
      return state;
  }
};

export default reducer;
