// Eppo Doc on getting assignments
// https://docs.geteppo.com/feature-flags/sdks/javascript#3-assign-variations
import * as EppoSdk from '@eppo/js-client-sdk';

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchVariationSuccess } from 'modules/experiments';

import { analyticsId } from 'modules/experiments/saga';

import { ApplicationState } from 'types/rootState';

const eppoClient = EppoSdk.getInstance();

import Cookies from 'js-cookie';

import type {
  Props,
  GetVariationFunction,
  AssignmentParams,
  AssignmentHandlerMap
} from 'modules/experiments/types';

/*
  ____________________________________________


  Full Documentation on how to setup an A/B test with Eppo:
  https://public.app.shortcut.com/9c/huckberry/docs/33y1tjpP3upqzq685FdTES/how-to-setup-an-ab-test-with-eppo


  ____________________________________________

  When setting up a test, include the <FeaturePreviewAction /> toolbar item on the path your feature will be using, and follow this pattern for Eppo test setup:
  ____________________________________________

  import useVariation from 'hooks/useVariation';

    // Eppo's flagOrExperimentKey, aka experimentKey
    const featureName = 'YOUR-FEATURE-NAME' 
    ----------------------------------------
    useVariation({
      featureName: string,       -- The Flag or Experiment name we are using
      variationLabels: string[], -- These are the optional variations, i.e. ['default', 'variation-b', 'variation-c']
      previewLabel: string,      -- This is for Admin preview in the toolbar
      targetingAttributes: {     -- These are optional params
        country: string,
        page: string
      },
      variationType: string,     -- A new required param in version 3.0 of Eppo SDK 
                                    This value describes what type the variations are, possible options are: "string", "boolean", "number", "JSON"
      defaultValue: string       -- A new required param in version 3.0 of Eppo SDK.
                                    This value will be returned when none of the allocations matched, when the flag was disabled, or when the flag configuration could not be retrieved from the server.
    });

    const variation = useSelector((state: ExperimentsState) =>
      state.experiments.variations[featureName]?.variation
    );
  
  ____________________________________________

    // Use the variation in your component with an if / else, or switch-case if more than 2 variations:

      if (variation === "default") {
      // do this
      } else if (variation === "variation-b") {
      // do that
      }
  ____________________________________________
*/

// Needed to call `window.rudderanalyticsLoaded`
declare global {
  interface Window {
    rudderanalyticsLoaded: boolean;
  }
}

// Eppo Assignment Wrappers

const getStringAssignment = ({
  subjectKey,
  eppoClient,
  featureName,
  targetingAttributes,
  defaultValue
}: AssignmentParams<string>) => {
  return eppoClient.getStringAssignment(
    featureName,
    subjectKey,
    targetingAttributes,
    defaultValue || 'default'
  );
};

const getBoolAssignment = ({
  subjectKey,
  eppoClient,
  featureName,
  targetingAttributes,
  defaultValue
}: AssignmentParams<boolean>) => {
  return eppoClient.getBoolAssignment(
    featureName,
    subjectKey,
    targetingAttributes,
    defaultValue || false
  );
};

const getNumberAssignment = ({
  subjectKey,
  eppoClient,
  featureName,
  targetingAttributes,
  defaultValue
}: AssignmentParams<number>) => {
  return eppoClient.getNumericAssignment(
    featureName,
    subjectKey,
    targetingAttributes,
    defaultValue || 0
  );
};

const getJSONAssignment = ({
  subjectKey,
  eppoClient,
  featureName,
  targetingAttributes,
  defaultValue
}: AssignmentParams<object>) => {
  return eppoClient.getJSONAssignment(
    featureName,
    subjectKey,
    targetingAttributes,
    defaultValue || {}
  );
};

const useVariation = ({
  featureName,
  variationLabels,
  previewLabel,
  targetingAttributes = {},
  shouldRefetch = false,
  variationFromBlock = null,
  variationType = 'string',
  defaultValue
}: Props) => {
  const [subjectKey, setSubjectKey] = React.useState(() => analyticsId());

  React.useEffect(() => {
    const handleRudderAnalyticsLoad = () => {
      if (window.rudderanalyticsLoaded && !subjectKey.id) {
        const newSubjectKey = analyticsId();
        setSubjectKey(newSubjectKey);
      }
    };

    // Add event listener
    window.addEventListener(
      'rudderanalyticsLoadedChange',
      handleRudderAnalyticsLoad
    );

    // Cleanup
    return () => {
      window.removeEventListener(
        'rudderanalyticsLoadedChange',
        handleRudderAnalyticsLoad
      );
    };
  }, []);

  const dispatch = useDispatch();

  const eppoIsLoaded = useSelector(
    (state: ApplicationState) => state.experiments.eppoIsLoaded
  );

  const variations = useSelector(
    (state: ApplicationState) => state.experiments.variations
  );

  // This will determine if we should fetch a new variation or not when there's already one in the store
  const shouldFetchVariation = shouldRefetch || !(featureName in variations);

  const getVariation: GetVariationFunction = React.useCallback(
    (subjectKey: string, eppoClient: EppoSdk.IEppoClient) => {
      const ASSIGNMENT_HANDLER_MAP: AssignmentHandlerMap = {
        string: getStringAssignment,
        boolean: getBoolAssignment,
        number: getNumberAssignment,
        JSON: getJSONAssignment
      };

      const assignmentHandler = ASSIGNMENT_HANDLER_MAP[variationType];

      if (!assignmentHandler) {
        return null;
      }

      return assignmentHandler({
        subjectKey,
        eppoClient,
        featureName,
        targetingAttributes,
        defaultValue
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logEppoVariation = Cookies.get('debug_eppo');

  React.useEffect(() => {
    const variation =
      eppoIsLoaded &&
      shouldFetchVariation &&
      featureName &&
      subjectKey.id &&
      typeof subjectKey.id === 'string' &&
      getVariation(subjectKey.id, eppoClient);

    logEppoVariation === 'true' &&
      console.table({
        variation,
        featureName,
        variationLabels,
        previewLabel,
        ...targetingAttributes,
        shouldRefetch,
        variationFromBlock,
        shouldFetchVariation,
        subjectKey,
        eppoIsLoaded,
        eppoClient
      });

    // if a variation was specified from the block
    // and it does not match eppo's response
    // do not update feature preview modal
    if (
      typeof variationFromBlock === 'string' &&
      variationFromBlock !== variation
    ) {
      return;
    }

    if (variation) {
      dispatch(
        fetchVariationSuccess(
          featureName,
          variation,
          variationLabels,
          previewLabel
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getVariation,
    shouldFetchVariation,
    subjectKey,
    eppoIsLoaded,
    eppoClient
  ]);

  return;
};

export default useVariation;
