import reducer from './reducer';

import * as actionCreators from './action-creators';

export const {
  fetchOutfits,
  fetchOutfitsSuccess,
  fetchOutfitsFailure,
  trackStylitics
} = actionCreators;

export type { StyliticsStateType } from './types';

export default reducer;
