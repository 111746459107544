// @flow

import type { ShippingTouchpointsType } from './types';

export const getShippingTouchpoints = () => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH'
});

export const getShippingTouchpointsSuccess = (
  data: ShippingTouchpointsType
) => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH_SUCCESS',
  data
});

export const getShippingTouchpointsFailure = (err: any) => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH_FAILURE',
  err
});
