import {
  StyliticsOutfitItem,
  StyliticsInsightsType,
  StyliticsTrackActionType
} from './types';

export const fetchOutfits = (seed: string) => ({
  type: 'STYLITICS/FETCH_OUTFITS',
  seed
});

export const fetchOutfitsSuccess = (outfits: StyliticsOutfitItem[]) => ({
  type: 'STYLITICS/FETCH_OUTFITS_SUCCESS',
  outfits
});

export const fetchOutfitsFailure = (seed: string) => ({
  type: 'STYLITICS/FETCH_OUTFITS_FAILURE',
  seed
});

export const trackStylitics = (action: StyliticsTrackActionType) => {
  const styliticsAnalytics = window?.StyliticsInsights?.create({
    client: 'huckberry'
  });

  if (styliticsAnalytics) {
    const { event, data } = action;
    switch (event) {
      case 'trackOutfitView':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) => {
          insights.trackOutfitView({
            outfit: data.outfit,
            outfit_position: data.outfit_position
          });
        });
      case 'trackOutfitClick':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) => {
          insights.trackOutfitClick({
            outfit: data.outfit,
            outfit_position: data.outfit_position,
            ui_component: data.ui_component
          });
        });
      case 'trackPurchase':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          insights.trackPurchase({
            client_user_id: data.client_user_id,
            order_id: data.order_id,
            order_total: data.order_total,
            items: data.items
          })
        );
      default:
        return;
    }
  }
};
