import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import { getCartAddonsSuccess, getCartAddonsFailure } from './action-creators';
import { fetchCartAddons } from './requests';

function* cartAddonsSaga(): SagaIterator {
  yield takeEvery('CART_ADDONS/GET_CART_ADDONS', getCartAddons);
}

function* getCartAddons(): SagaIterator {
  try {
    const cartAddons = yield call(fetchCartAddons);
    yield put(getCartAddonsSuccess(cartAddons.data));
  } catch (err) {
    return getCartAddonsFailure('Error fetching cart addons');
  }
}

export default cartAddonsSaga;
