import { ProductTilePayloadType } from 'types/products';

const getCartAddons = () => ({
  type: 'CART_ADDONS/GET_CART_ADDONS'
});

const getCartAddonsSuccess = (products: ProductTilePayloadType[]) => ({
  type: 'CART_ADDONS/GET_CART_ADDONS_SUCCESS',
  products
});

const getCartAddonsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_CART_ADDONS_FAILURE',
  error: err
});

export { getCartAddons, getCartAddonsSuccess, getCartAddonsFailure };
